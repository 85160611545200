<script>
import APIStore from '@app/services/API/Store';

export default {
  data() {
    return {
      isFetching: false,
      isLoading: false,
      formData: {
        type: 'students',
      },
    };
  },

  computed: {
    canExportAbandonedCart() {
      return (
        this.$store.getters['auth/isNotPercentPlan']
      );
    },
  },

  methods: {
    async submit() {
      this.isLoading = true;

      if (this.formData.type === 'students') {
        await this.exportStudentsCSV();
      }

      if (this.formData.type === 'abandoned-cart') {
        await this.exportAbandonedCartCSV();
      }

      this.isLoading = false;
      return null;
    },

    exportAbandonedCartCSV() {
      return APIStore.exportAbandonedCart(this.$store.getters['auth/store'].uuid)
        .then(() => this.$buefy.dialog.alert(`
          Un export CSV listant TOUS vos paniers abandonés
          vous sera envoyé par email.
          <strong>Attention : le traitement peut prendre plusieurs minutes !</strong>
        `))
        .finally(() => this.$emit('done'));
    },

    exportStudentsCSV() {
      return APIStore.exportCustomers(this.$store.getters['auth/store'].uuid)
        .then(() => this.$buefy.dialog.alert(`
          Un export CSV listant TOUS vos apprenants
          vous sera envoyé par email.
          <strong>Attention : le traitement peut prendre plusieurs minutes !</strong>
        `))
        .finally(() => this.$emit('done'));
    },
  },
};
</script>

<template>
  <div>
    <h1 class="title is-4">
      Export CSV <AppFeatureBadge v-if="formData.type === 'abandoned-cart'"
                                  class="ml-3"
                                  position="is-bottom" />
    </h1>
    <p v-if="formData.type === 'students'" class="mb-3">
      Obtenez un export de vos apprenants et des informations liées à leur assiduité
      (résultats aux quiz, temps passé sur les formations, etc).
    </p>
    <p v-else class="mb-3">
      Obtenez un export des personnes qui n'ont pas finalisé leur inscription
      à l'une de vos formations : ce sont les <strong>paniers abandonnés</strong>.
    </p>

    <template>
      <p v-if="formData.type === 'students'" class="notification is-info">
        La liste des apprenants comprend les apprenants actifs mais également ceux dont le statut
        est "bloqué".
      </p>
      <p v-else class="notification is-info">
        Rentrez en contact avec vos paniers abandonnés <strong>pour savoir ce qui les a empêché
        de finaliser leur inscription</strong>.
      </p>

      <go-pro-wrapper v-if="formData.type === 'abandoned-cart'" planLock="PRO" class="mb-3" />

      <form @submit.prevent="submit">
        <b-field label="Données à exporter">
          <b-select v-model="formData.type" required expanded>
            <option value="students">
              Liste des apprenants
            </option>
            <option value="abandoned-cart">
              Liste des paniers abandonnés
            </option>
          </b-select>
        </b-field>

        <div class="buttons is-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading"
            :disabled="formData.type === 'abandoned-cart' && !canExportAbandonedCart">
            Exporter
          </b-button>
        </div>
      </form>
    </template>
  </div>
</template>
